import React from 'react';
import Layout from '../components/layout/layout';
import { graphql, useStaticQuery } from "gatsby"

import '../styles/podcastsPage.scss';

import golangMigrateImage from '../assets/images/golang_migrate.jpg';

const VideosPage = ({ location }) => {
  const data = useStaticQuery(graphql`
      query VideosPage {
        allContentfulVideo {
          edges {
            node {
              id
              name
              description {
                description
              }
              link
              image {
                file {
                  url
                }
              }
            }
          }
        }
      }
    `
  )

  return <Layout location={location}>
    <div className="Podcasts">
      <h1>Videos</h1>
      <div className="Podcasts__content">
        {data.allContentfulVideo.edges.map(({ node }) => {
          return <a
            href={node.link}
            target="_blank"
            className="PodcastItem"
          >
            <img className="PodcastItem__image" src={node.image.file.url} />
            <div className="PodcastItem__content">
              <p className="PodcastItem__title">
                {node.name}
              </p>
              <div className="PodcastItem__description">
                <p>
                  {node.description.description}
                </p>
              </div>
            </div>
          </a>
        })}
      </div>
    </div>
  </Layout>
}

export default VideosPage